.blog-read-content{
  display:-webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-flex:1;
    
  display:-moz-box;
  -moz-box-orient: vertical;
  -moz-box-flex:1;
    
  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;

  display: flex;
  display:-webkit-flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 80%;
  margin-bottom: 10%;

  color:#000000;
  border: 1px solid #e9dfdf;
  border-radius: 4px;
  // background: green;
};

.blog-read{
  display:-webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-flex:1;
    
  display:-moz-box;
  -moz-box-orient: vertical;
  -moz-box-flex:1;
    
  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;

  display: flex;
  display:-webkit-flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #ffffff;
  // background: yellow;
  margin-top: 0%;
  //for modal menu
  // margin-top: 8%;
  // overflow-y: scroll;
  // scroll-behavior: smooth;  
};

.blog-header-text-div{
  display:-webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-flex:1;
    
  display:-moz-box;
  -moz-box-orient: vertical;
  -moz-box-flex:1;
    
  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;

  display: flex;
  display:-webkit-flex;
  flex-direction: column;
  width: 78%;
  padding: 1%;
  border: 1px solid #e9dfdf;
  border-radius: 4px;
  margin: 5% 5%;
}

.blog-header-text{
  color:#000000;
  font-size: 50px;
  line-height: 80%;
};

.blogImage{
  display:-webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-flex:1;
    
  display:-moz-box;
  -moz-box-orient: vertical;
  -moz-box-flex:1;
    
  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;

  display: flex;
  display:-webkit-flex;
  flex-direction: column;
  width: 80%;
  height: 20rem;
};



.blog-para-style{
  margin: 2% 2%;
  line-height: 130%;
  text-align: left;
  font-size: 20px;
  line-height: 1.6;
  font-weight: 200;
  word-break: break-word;

};

.closeModal{
 width:2rem;
};