*{
  padding:0;
  margin:0;
};

a{
  text-decoration: none;
  font-size: 0.8em;
};

li{
  list-style: none;
};


body{
  display:-webkit-box;
  -webkit-box-pack:center;
    
  display:-moz-box;
  -moz-box-pack:center;
    
  display:-o-box;
 -o-box-pack:center;

  display: -ms-flexbox;

  display: flex;


  width:100%;
  background: #ffffff;
  font-size: 14px;
  line-height: 1.8;
  color: #000;
  background-color: #fff;
};

html, body
{
  height: 100%;
  text-align: center;
  justify-content: center;
  // font-family: EuclidCircularA-Regular;
  font-family: 'EuclidCircularA-BoldItalic';
};

body, input, textarea, button, select {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  box-shadow: none;
  outline: none;
  -webkit-appearance: none;
};