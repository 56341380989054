
.top-flight-short{
  display:-webkit-box;
  -webkit-box-flex:1;
    
  display:-moz-box;
  -moz-box-flex:1;
    
  display:-o-box;
  -o-box-flex:1;

  display: -ms-flexbox;
  

  display: flex;
  display:-webkit-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  // position: absolute;
};

.topflightadsimg{
  display:-webkit-box;
  -webkit-box-flex:1;
    
  display:-moz-box;
  -moz-box-flex:1;
    
  display:-o-box;
  -o-box-flex:1;

  display: -ms-flexbox;
  

  display: flex;
  display:-webkit-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 65%;
  // height: 30em;
};


@media screen and (max-width: 960px){

  .topflightadsimg{
    width: 100%;
  };
};