.product-pagination{
  display:-webkit-box;
  -webkit-box-orient: horizontal;
  -webkit-box-flex:1;
    
  display:-moz-box;
  -moz-box-orient: horizontal;
  -moz-box-flex:1;
    
  display:-o-box;
  -o-box-orient:horizontal;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: row;
  flex-direction: row;

  display: flex;
  display:-webkit-flex;
  flex-direction: row;

  width:100%;
  margin-bottom: 3%;
};

.product-pagination-tab{
  width: 100%;
};

.product-pag-td0{
  text-align: left;
  width:50%
};

.product-pag-td1{
  text-align: right;
  width:50%
};

.product-button0{
  width:30%;
  height: 2.5em;
  margin-left: 6%;
  border-style: none;
  background: #fff;
  border: .6px solid #fca120;
  border-radius: 2px;
};

.product-button1{
  width:30%;
  height: 2.5em;
  border-style: none;
  background: #fff;
  border: .6px solid #fca120;
  border-radius: 2px;
  margin-right: 2.9%;
};

.product-button0:hover{
  opacity: .8;
  border: .6px solid #0b34ee;
};

.product-button1:hover{
  opacity: .8;
  border: .6px solid #0b34ee;
};