.cat-search-div{
  display:-webkit-box;
  -webkit-box-orient: horizontal;
  -webkit-box-flex:1;
    
  display:-moz-box;
  -moz-box-orient: horizontal;
  -moz-box-flex:1;
    
  display:-o-box;
  -o-box-orient:horizontal;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: row;
  flex-direction: row;

  display: flex;
  display:-webkit-flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 10%;
  margin:2% 0%;
  color:#000000;
};

.blog-categories{
  display:-webkit-box;
  -webkit-box-orient: horizontal;
  -webkit-box-flex:1;
    
  display:-moz-box;
  -moz-box-orient: horizontal;
  -moz-box-flex:1;
    
  display:-o-box;
  -o-box-orient:horizontal;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: row;
  flex-direction: row;

  display: flex;
  display:-webkit-flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: flex-start;
  width: 58%;
  margin-left: 2%;
  height: 100%;
};

.catSearchCase{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width:80%;
  height: 100%;
};

.categories{
  margin: 0% 1%;
  padding: 0.5%;
  border-bottom: 2px solid #0414e9b7;

  border-radius: 4px;
  overflow: hidden;
  transition: all .2s linear;
};

.blog-touch-cat{
  font-size: 0.9rem;
  color:#000000;
};

.blog-search{
  display:-webkit-box;
  -webkit-box-orient: horizontal;
  -webkit-box-flex:1;
    
  display:-moz-box;
  -moz-box-orient: horizontal;
  -moz-box-flex:1;
    
  display:-o-box;
  -o-box-orient:horizontal;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: row;
  flex-direction: row;

  display: flex;
  display:-webkit-flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 100%;
};

.categories1:hover{
  border-bottom: 2px solid #ebfc07b7;
  opacity: .68;
  box-shadow: 2px 8px 45px rgba(0, 0, 0, .15);
  transform: translate3D(0, -2px, 0);
};

// #category1:hover{
//   border-bottom: 2px solid #ebfc07b7;
//   opacity: .68;
//   box-shadow: 2px 8px 45px rgba(0, 0, 0, .15);
//   transform: translate3D(0, -2px, 0);
// };

// #category2:hover{
//   border-bottom: 2px solid #ebfc07b7;
//   opacity: .68;
//   box-shadow: 2px 8px 45px rgba(0, 0, 0, .15);
//   transform: translate3D(0, -2px, 0);
// };

// #category3:hover{
//   border-bottom: 2px solid #ebfc07b7;
//   opacity: .68;
//   box-shadow: 2px 8px 45px rgba(0, 0, 0, .15);
//   transform: translate3D(0, -2px, 0);
// };

// #category4:hover{
//   border-bottom: 2px solid #ebfc07b7;
//   opacity: .68;
//   box-shadow: 2px 8px 45px rgba(0, 0, 0, .15);
//   transform: translate3D(0, -2px, 0);
// };

// #category5:hover{
//   border-bottom: 2px solid #ebfc07b7;
//   opacity: .68;
//   box-shadow: 2px 8px 45px rgba(0, 0, 0, .15);
//   transform: translate3D(0, -2px, 0);
// };

// #category6:hover{
//   border-bottom: 2px solid #ebfc07b7;
//   opacity: .68;
//   box-shadow: 2px 8px 45px rgba(0, 0, 0, .15);
//   transform: translate3D(0, -2px, 0);
// };

.blog-search{
  position: relative;
};

.blog-search-form{
  // background: blue;
  display:-webkit-box;
  -webkit-box-orient: horizontal;
  -webkit-box-flex:1;
    
  display:-moz-box;
  -moz-box-orient: horizontal;
  -moz-box-flex:1;
    
  display:-o-box;
  -o-box-orient:horizontal;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: row;
  flex-direction: row;

  display: flex;
  display:-webkit-flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
};

.blog-search-button{
  position: absolute;
  right: 18%;
  top: 33%;
};

.blog-input-search{
  display:-webkit-box;
  -webkit-box-orient: horizontal;
  -webkit-box-flex:1;
    
  display:-moz-box;
  -moz-box-orient: horizontal;
  -moz-box-flex:1;
    
  display:-o-box;
  -o-box-orient:horizontal;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: row;
  flex-direction: row;

  display: flex;
  display:-webkit-flex;
  flex-direction: row;

  width: 70%;
  height: 55%;
  border-style: none;
  border: 1px solid #9e9b9b;
  border-radius: 4px;
  color: #383636;
};

.blog-pagination-cover{
  display:-webkit-box;
  -webkit-box-orient: horizontal;
  -webkit-box-flex:1;
    
  display:-moz-box;
  -moz-box-orient: horizontal;
  -moz-box-flex:1;
    
  display:-o-box;
  -o-box-orient:horizontal;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: row;
  flex-direction: row;

  display: flex;
  display:-webkit-flex;
  flex-direction: row;

  width: 90%;
  margin: 1% 4%;
};

//blog ads.


.Ads-card-utako{
  display:-webkit-box;
  -webkit-box-orient: horizontal;
  -webkit-box-flex:1;
    
  display:-moz-box;
  -moz-box-orient: horizontal;
  -moz-box-flex:1;
    
  display:-o-box;
  -o-box-orient:horizontal;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: row;
  flex-direction: row;

  display: flex;
  display:-webkit-flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
};

.ads-child-display{
  display:-webkit-box;
  -webkit-box-flex:1;
    
  display:-moz-box;
  -moz-box-flex:1;
    
  display:-o-box;
  -o-box-flex:1;

  display: -ms-flexbox;

  display: flex;
  display:-webkit-flex;
 
  width: 30%;
  height: 30em;
  margin: 2%;
};


@media screen and (max-width: 794px){
  .cat-search-div{
    display:-webkit-box;
    -webkit-box-orient: vertical;
    -webkit-box-flex:1;
      
    display:-moz-box;
    -moz-box-orient: vertical;
    -moz-box-flex:1;
      
    display:-o-box;
    -o-box-orient:vertical;
    -o-box-flex:1;
  
    display: -ms-flexbox;
    -ms-flex-direction: column;
    flex-direction: column;
  
    display: flex;
    display:-webkit-flex;
    flex-direction: column;
  };

  .blog-categories{
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-left: 0%;
    height: 50%;
  };

  .blog-search{
    width: 100%;
    height: 50%;
  };

  .Ads-card-utako{
    display:-webkit-box;
    -webkit-box-orient: vertical;
    -webkit-box-flex:1;
      
    display:-moz-box;
    -moz-box-orient: vertical;
    -moz-box-flex:1;
      
    display:-o-box;
    -o-box-orient:vertical;
    -o-box-flex:1;
  
    display: -ms-flexbox;
    -ms-flex-direction: column;
    flex-direction: column;
  
    display: flex;
    display:-webkit-flex;
    flex-direction: column;
    
  };

  .ads-child-display{
    width: 80%;
    height: 30em;
    margin: 2%;
  };
};