.blog-dynamic{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 6%;
  width: 90%;
  margin-bottom: 3%;
  // background: #000000;
};

.namic-child{
  text-align: left;
  justify-content:left;
  align-items:left;
  font-family: 'Playfair Display',"CenturyGothic","HelveticaNeue","Helvetica Neue",Helvetica,Arial,Tahoma,sans-serif;
  width:30%;
  margin: 1%;
  overflow: hidden;
  transition: all .2s linear;
  border-radius: 4px;
  // height: 19rem;
};

.namic-child:hover {
  transform: translate3D(0, -2px, 0);
};


.namic-blogImg{
  width: 100%;
  height: 20rem;
};

.namic-blogh1{
  margin-top: 4%;
  line-height: 1.6;
  font-weight: 600;
  font-size: 15px;
  word-break: break-word;
};

.namic-p {
  line-height: 1.6;
  font-weight: 200;
  word-break: break-word;
  font-size: 0.81rem;
};

.namic-butt {
  margin-top: 2%;
  width: 50%;
  margin-left: 25%;
  border-style: none;
  padding: 0.78rem;
  font-size: 0.86rem;
  background: #FFD700;
  color: #fff;
  border-radius: 14px
};

.namic-butt:hover{
  opacity: .7;
};

.blog-dynamic-error{
  display:-webkit-box;
  -webkit-box-orient: vertical;
  -webkit-box-flex:1;
    
  display:-moz-box;
  -moz-box-orient: vertical;
  -moz-box-flex:1;
    
  display:-o-box;
  -o-box-orient:vertical;
  -o-box-flex:1;

  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;

  display: flex;
  display:-webkit-flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;

  width:100%;
  height: 20em;
  // background: #000000;
};


@media screen and (max-width: 910px) {
  .namic-child  {
    flex: 1 1 calc(40% - 2rem);
  };

};


@media screen and (max-width: 550px) {
  .namic-child  {
    flex: 1 1 calc(90% - 2rem);
  };
};
