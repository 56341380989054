html, body
{
  height: 100%;
  text-align: center;
  justify-content: center;
  font-family: SourceSansPro;
};

body {
  display:-webkit-box;
  -webkit-box-pack:center;
    
  display:-moz-box;
  -moz-box-pack:center;
    
  display:-o-box;
 -o-box-pack:center;

  display: -ms-flexbox;

  display: flex;


  width:100%;
  background: #ffffff;
  font-size: 20px;
  line-height: 1.8;
  color: #000;
  background-color: blue;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
};

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
};

.modal{
  color:#000000;
  width:85%;
  margin-top: 50% auto;
  /* height:50%; */
  padding-top: 3%;
  text-align: center;
  overflow-y: scroll;
  background: #e9e9e9;
};

.modal__button{
  font-family: 'cfont';
  font-size: 0.8rem;
  border: none;
  width:40%;
  height:1.9rem;
  padding: 0.3rem;
  margin-top: 2%;
  margin-right: 1rem;
  margin-bottom:8%;
};

.modal__button:hover{
  color:#d6d2d2
};

.modal__option{
  font-size: 1.4rem;
  font-style: normal;
};

.ReactModalPortal>div{
  opacity: 0;
};

.ReactModalPortal .ReactModal__Overlay{
  text-align: center;
  transition: opacity 200ms ease-in-out;
};

.ReactModalPortal .ReactModal__Overlay--after-open{
  opacity: 1;
};

.ReactModalPortal .ReactModal__Overlay--before-close{
  opacity: 0;
};
